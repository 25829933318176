import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Menu from "./molecules/menu/menu"
import DesignNav from "./atoms/designNav"
import Cursor from "./molecules/cursor/cursor"
import 'intersection-observer'

const Layout = ({ children, location, background, pageContext }) => {
  const data = useStaticQuery(graphql`
    query GetCategoriesAndTopics  {
      allWpCategory {
        nodes {
          id
          name
          slug
          description
          count
        }
      }
      allWpTopic {
        nodes {
          id
          name
          slug
          description
          count
        }
      }    
    }


  `)

  const [showDesignNav, setShowDesignNav] = useState(
    (location?.pathname || "").includes("design") || (location?.pathname || "").includes("top")
  )

  useEffect(() => {
    const path = (location?.pathname || "")
    setShowDesignNav(path.includes("design") || path.includes("top"))
  }, [location?.pathname])

//  useEffect(() => {
//    const container = document.querySelector('#container')
//    const tlEgdes = container.querySelector('.tl-edges')
//    
//    if ((container.clientWidth === tlEgdes.clientWidth) && (container.clientHeight !== tlEgdes.clientHeight)) 
//      container.style.paddingRight = '15px'
//
//  }, [])

  return (
    <>
      <Cursor location={location} />
      <Menu location={location} />
      {showDesignNav && <DesignNav location={location} count={20} taxonomies={data}/>}
      <main
        style={{
          background: background,
          height: '100vh',
          maxHeight: '-webkit-fill-available',
          // scrollSnapType: isMobile ? "unset" : undefined,
        }}
        className={background === "var(--gradient)" ? "gradient" : ""}
        id="container"
      >
        {children}
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
