import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'

const Filters = ({categories, topics}) => {

    const [activeFilter, setActiveFilter] = useState('')
    const [menuOpen, _] = useState(true)

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    useEffect(() => {
        const path = (location?.pathname || "")
        
        if (path.includes('design')) {
            const filter = queryParams.get('filter')
            setActiveFilter(filter)
        }

        else {
            setActiveFilter('')
        }

    }, [location?.pathname])


    const isDesign = useMemo(() => {
        return location.pathname.includes('design')
    })
    

    return (
        <div className='filters'>
            
            <br />
            <ul className={'menuOpen' }> 
                {categories.map((category, index) => (category.slug === 'uncategorized-en' ? null :
                    
                (               
                    <li key={index}
                        onClick={() => {
                            setActiveFilter(category.slug)
                        }}
                        className={activeFilter === category.slug ? 'active' : ''}
                    >  
                        <Link to={`/designs?filter=${category.slug}`}>
                            {category.name}
                        </Link>
                    </li>
                )))}

                <br />

                {topics.map((topic, index) => (
                    
                    <li key={index}
                        onClick={() => {
                            setActiveFilter(topic.slug)
                        }}
                        className={activeFilter === topic.slug ? 'active' : ''}
                    >
                        <Link to={`/designs?filter=${topic.slug}`}>
                            {topic.name}
                        </Link>
                    </li>
                ))}

                <br />

                    <li onClick={() => {
                        setActiveFilter('everything')
                    }}
                    className={activeFilter === 'everything' ? 'active' : ''}
                >
                    <Link to={`/designs`}> 
                        everything
                    </Link>
                </li>
            </ul>
            {!menuOpen && activeFilter && location.pathname.includes('design') ? <div style={{color: 'var(--pink)'}}>
                {categories.find(category => category.slug === activeFilter)?.name || topics.find(topic => topic.slug === activeFilter)?.name || 'everything'}
            </div> : null }
        </div>
    );
};

export default Filters;