import React, { useContext, useMemo } from "react"
import { CursorContext } from '../../context/cursorContext'
import { Link } from "gatsby"
import Filters from "./filters"


const DesignNav = ({ location, count, taxonomies }) => {
  const currentPage = (location?.pathname || "").includes("top")
    ? "top"
    : "design"

  const categories = useMemo(() => {
    return taxonomies.allWpCategory.nodes
  })

  const topics = useMemo(() => {
    return taxonomies.allWpTopic.nodes
  })

  return (
    <nav id="design-nav">
      <ul>
        <li>
          <Link
            to="/top"
            className={`design-nav-link ${
              currentPage === "top" ? "active" : ""
            }`}
          >
            featured
          </Link>
        </li>
        <li>
          {/* <Link
            to="/designs"
            className={`design-nav-link ${
              currentPage === "design" ? "active" : ""
            }`}
          > */}
            <Filters categories={categories} topics={topics}/>
          {/* </Link> */}


        </li>
      </ul>
    </nav>
  )
}

export default DesignNav
